// @ts-nocheck
'use client';

import { Button, Heading, Icon } from 'oddsgate-ds';
import {
    StyledLanguageSelector,
    StyledOverlay,
    StyledTrigger,
} from './LanguageSwitcher.theme';

import { Language } from '@/lib/helpers/getI18n';
import Link from 'next/link';
import { removeScroll } from '@/lib/helpers/scrollHelpers';
import { useGlobalContext } from '@/lib/globalContext';
import { usePathname } from 'next/navigation';
import { useState } from 'react';

const LanguageSwitcher = () => {
    const state = useGlobalContext();

    const [menuActive, setMenuActive] = useState(false);
    const stateWPML = state?.WPML_domains ?? [];
    const WPML = Object.keys(state?.WPML_domains as []) ?? [];

    const languageOnClick = (isActive: boolean, search: boolean) => {
        removeScroll(isActive, state);

        setMenuActive(isActive);
    };

    if (!state?.WPML_domains || Object.keys(state?.WPML_domains).length <= 1) {
        return <></>;
    }

    return (
        <div className="d-inline-flex position-relative">
            <StyledTrigger
                variant="secondary"
                mode="light"
                $active={menuActive}
                rightIcon={<Icon icon="icon-arrowUp" />}
                onClick={() => languageOnClick(!menuActive, false)}>
                {state.dict?.custom?.location}
            </StyledTrigger>

            <StyledLanguageSelector
                $active={menuActive}
                data-lenis-prevent={true}>
                <div>
                    <ul className="">
                        {WPML?.map((locale: string) => {
                            return (
                                <li
                                    key={`locale-${locale}`}
                                    className="mb-5 mt-md-1 mb-md-0">
                                    <Link
                                        // href={state.translatedUri ? state.translatedUri[locale] ? (state.translatedUri[locale] as unknown as string) : `/${locale}/` : ""}
                                        href={stateWPML[locale]?.custom_url}
                                        onClick={() =>
                                            languageOnClick(false, false)
                                        }>
                                        <Heading
                                            tag="span"
                                            size="h5"
                                            className={`${state.lang === locale ? 'color-primary50' : 'color-third50'} fw-bold text-uppercase`}>
                                            {stateWPML[locale].name}
                                        </Heading>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </StyledLanguageSelector>
            <StyledOverlay
                $active={menuActive}
                onClick={() => languageOnClick(false, false)}></StyledOverlay>
        </div>
    );
};

export default LanguageSwitcher;
